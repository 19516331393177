import {Component, OnInit, Input, OnDestroy, Renderer2} from '@angular/core';
import {Subscription} from 'rxjs';
import {ThemeService} from '../../../shared/services/theme.service';
import {TranslateService} from '@ngx-translate/core';
import {LayoutService} from '../../services/layout.service';
import {JwtAuthService} from 'app/shared/services/auth/jwt-auth.service';
import {NavigationPublicService} from "../../services/navigation-public.service";
import {category_list} from "../../constants/category_list";
import {filter} from "rxjs/operators";
import {NavigationEnd, Router} from "@angular/router";

@Component({
    selector: 'app-header-public',
    templateUrl: './header-public.component.html'
})
export class HeaderPublicComponent implements OnInit, OnDestroy {
    layoutConf: any;
    menuItems: any;
    category_list = category_list
    menuEnabled:boolean = false
    search_value: string = '';
    menuItemSub: Subscription;
    egretThemes: any[] = [];
    recent_news: any = [];
    @Input() notificPanel;

    constructor(
        private layout: LayoutService,
        private navService: NavigationPublicService,
        public themeService: ThemeService,
        public translate: TranslateService,
        private renderer: Renderer2,
        public jwtAuth: JwtAuthService,
        private router: Router
    ) {
        // Close sidenav after route change in mobile
        router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((routeChange: NavigationEnd) => {
                this.menuEnabled = false
            });
    }
    ngOnInit() {
        this.layoutConf = this.layout.layoutConf;
        this.egretThemes = this.themeService.egretThemes;
        this.menuItemSub = this.navService.menuItems$
            .subscribe(res => {
                res = []
                const categories = category_list.map(item => {
                    if (item.section_slug == 'wit') {
                        res.push( {
                            name: item.description,
                            type: 'link',
                            state: item.slug,
                            icon: ''
                        })
                    }
                })
                let limit = 10
                let mainItems: any[] = res.slice(0, limit)
                if (res.length <= limit) {
                    return this.menuItems = mainItems
                }
                let subItems: any[] = res.slice(limit, res.length )
                mainItems.push({
                    name: '',
                    type: 'dropDown',
                    tooltip: 'Ver más',
                    icon: 'keyboard_arrow_down',
                    sub: subItems
                })
                this.menuItems = mainItems
            })
    }

    ngOnDestroy() {
        this.menuItemSub.unsubscribe()
    }


    toggleSidenav() {
        this.menuEnabled = !this.menuEnabled
    }
}
