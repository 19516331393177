import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

import {AppAddComponent} from "./app-add.component";
import {FormGroup} from "@angular/forms";

interface confirmData {
  title?: string,
  message?: string
  item?: any
  editFields?: any[]
}

@Injectable()
export class AppAddService {

  constructor(private dialog: MatDialog) { }

  public edit(data:confirmData = {}): Observable<any> {
    data.title = data.title || 'Confirmar';
    data.message = data.message || 'Estás seguro?';
    data.item = data.item || null;
    data.editFields = data.editFields || null;
    let dialogRef: MatDialogRef<AppAddComponent>;
    dialogRef = this.dialog.open(AppAddComponent, {
      width: '380px',
      disableClose: true,
      data: {title: data.title, message: data.message, item: data.item, editFields: data.editFields}
    });
    return dialogRef.afterClosed();
  }
}
