import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';

@Component({
    selector: 'app-confirm',
    template: `<h1 matDialogTitle class="mb-8">{{ data.title }}</h1>
    <div mat-dialog-content class="mb-16" [innerHTML]="data.message"></div>
    <div mat-dialog-actions class="pb-16">
        <button
                type="button"
                mat-raised-button
                color="warn"
                (click)="dialogRef.close(1)">Reportar Número
        </button>
        <button
                type="button"
                mat-raised-button
                color="warn"
                (click)="dialogRef.close(2)">Reportar Email
        </button>
        <button
                type="button"
                color="primary"
                mat-raised-button
                (click)="dialogRef.close(3)">Cancelar
        </button>
    </div>`,
})
export class AppReportComponent {
    constructor(
        public dialogRef: MatDialogRef<AppReportComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }
}
