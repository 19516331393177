<div class="mat-elevation-z8">

    <div fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="600px" fxFlex.lt-sm="column">
            <mat-card>
                <mat-card-content>
                    <div class="ticket-header">
                        <h2>Detalles del Ticket</h2>
                    </div>
                    <div fxLayout="column" *ngIf="ticketData">
                        <div fxFlex="" fxLayout="row" class="py-16">
                            <div fxFlex="" class="text-muted-white">Número de Ticket</div>
                            <div fxFlex=""
                                 class="text-right justify-content-right font-weight-bold">{{ ticketData.ticket_number }}
                            </div>
                        </div>
                        <div fxFlex="" fxLayout="row" class="py-16">
                            <div fxFlex="" class="text-muted-white">Origen del Servicio</div>
                            <div fxFlex=""
                                 class="text-right justify-content-right font-weight-bold">{{ ticketData.service_origin }}
                            </div>
                        </div>
                        <div fxFlex="" fxLayout="row" class="py-16">
                            <div fxFlex="" class="text-muted-white">Destino del Servicio</div>
                            <div fxFlex=""
                                 class="text-right justify-content-right font-weight-bold">{{ ticketData.service_destination }}
                            </div>
                        </div>
                        <div fxFlex="" fxLayout="row" class="py-16">
                            <div fxFlex="" class="text-muted-white">Registro de Equipaje</div>
                            <div fxFlex=""
                                 class="text-right justify-content-right font-weight-bold">{{ ticketData.baggage_record }}
                            </div>
                        </div>
                        <div fxFlex="" fxLayout="row" class="py-16">
                            <div fxFlex="" class="text-muted-white">Fecha de Embarque</div>
                            <div fxFlex=""
                                 class="text-right justify-content-right font-weight-bold">{{ ticketData.ticket_date }}
                            </div>
                        </div>
                        <div fxFlex="" fxLayout="row" class="py-16">
                            <div fxFlex="" class="text-muted-white">Hora de Embarque</div>
                            <div fxFlex=""
                                 class="text-right justify-content-right font-weight-bold">{{ ticketData.ticket_time }}
                            </div>
                        </div>
                        <div fxFlex="" fxLayout="row" class="py-16">
                            <div fxFlex="" class="text-muted-white">Nombre del Pasajero</div>
                            <div fxFlex=""
                                 class="text-right justify-content-right font-weight-bold">{{ ticketData.passenger_name }}
                            </div>
                        </div>
                        <div fxFlex="" fxLayout="row" class="py-16">
                            <div fxFlex="" class="text-muted-white">DNI del Pasajero</div>
                            <div fxFlex=""
                                 class="text-right justify-content-right font-weight-bold">{{ ticketData.passenger_dni }}
                            </div>
                        </div>
                        <div fxFlex="" fxLayout="row" class="py-16">
                            <div fxFlex="" class="text-muted-white">Número de Asiento</div>
                            <div fxFlex=""
                                 class="text-right justify-content-right font-weight-bold">{{ ticketData.seat_number }}
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <div class="d-block w-100 text-center text-muted">
                <a href="https://www.wit.la/">
                    <p >Desarrollado por </p>
                    <img src="/assets/images/logo-wit.png" alt="landar.cl" class="d-block px-16 m-auto"
                         [ngStyle]="{'height':'40px'}">
                </a>
            </div>
        </div>
    </div>
</div>