import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { NavigationService } from "../../../shared/services/navigation.service";
import { ThemeService } from "../../services/theme.service";
import {Subscription, throwError} from "rxjs";
import { ILayoutConf, LayoutService } from "app/shared/services/layout.service";
import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";
import {LocalStoreService} from "../../services/local-store.service";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: "app-sidebar-side",
  templateUrl: "./sidebar-side.component.html"
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public layoutConf: ILayoutConf;
  APP_USER = "WIT_USER";
  constructor(
    private navService: NavigationService,
    private http: HttpClient,
    public themeService: ThemeService,
    private layout: LayoutService,
    private ls: LocalStoreService,
    public jwtAuth: JwtAuthService
  ) {}

  ngOnInit() {
    this.layoutConf = this.layout.layoutConf;
    this.http.get(`${environment.socket}/menu`).subscribe(data => {
      const menu_data:any = data
      if(menu_data?.success && menu_data?.menu) {
        this.ls.setItem('MENU_DATA', menu_data?.menu)
        const groupedMenus = menu_data.menu.reduce((acc, current) => {
          (acc[current.role_name] = acc[current.role_name] || []).push(current);
          return acc;
        }, {});

        const newMenuData = [];
        for (const role_name in groupedMenus) {
          newMenuData.push({
            type: 'dropDown',
            name: role_name,
            isOpen: false,
            icon: 'view_agenda',
            sub: groupedMenus[role_name].map(gm => {
              return {
                name: gm.module_name,
                type: 'link',
                isOpen: false,
                state: gm.module_route,
                icon: gm.module_icon
              }
            })
          })
        }

        this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
          this.menuItems = newMenuData;
        });

      }

    })

    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;

  }
  ngAfterViewInit() {
  }
  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }
  toggleCollapse() {
    if (
      this.layoutConf?.sidebarCompactToggle
    ) {
        this.layout.publishLayoutChange({
        sidebarCompactToggle: false
      });
    } else {
        this.layout.publishLayoutChange({
            // sidebarStyle: "compact",
            sidebarCompactToggle: true
          });
    }
  }
}
