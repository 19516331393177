<div class="header-topnav mat-elevation-z2">

    <div
            fxLayout="row wrap"
            fxLayout.lt-sm="column"
            fxLayoutAlign="center center"
            fxLayoutGap="8px">
        <div fxFlex="nogrow" routerLink="/" class="pr-16">
            <img src="/assets/images/logo-wit.png" alt="landar.cl" class="px-16 m-auto"
                 [ngStyle]="{'height':'60px'}">
        </div>
        <div fxFlex="">
            <div class="topnav" *ngIf="!layoutConf.isMobile || menuEnabled">
                <!-- App Logo -->
                <ul class="menu" fxFlex="">
                    <li *ngFor="let item of menuItems; let i = index;">
                        <div *ngIf="item.type !== 'separator'" routerLinkActive="open">
                            <a matRipple routerLink="/{{item.state}}" *ngIf="item.type === 'link'">
                                {{item.name | translate}}
                            </a>
                            <div *ngIf="item.type === 'dropDown'">
                                <label matRipple for="drop-{{i}}" class="toggle">
                                    <mat-icon>{{item.icon}}</mat-icon>
                                    {{item.name | translate}}</label>
                                <a matRipple>
                                    <mat-icon>{{item.icon}}</mat-icon>
                                    {{item.name | translate}}</a>
                                <input type="checkbox" id="drop-{{i}}"/>
                                <ul>
                                    <li *ngFor="let itemLvL2 of item.sub; let j = index;"
                                        routerLinkActive="open">
                                        <a matRipple
                                           routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}"
                                           *ngIf="itemLvL2.type !== 'dropDown'">
                                            <mat-icon *ngIf="itemLvL2.icon">{{itemLvL2.icon}}</mat-icon>
                                            {{itemLvL2.name | translate}}
                                        </a>

                                        <div *ngIf="itemLvL2.type === 'dropDown'">
                                            <label matRipple for="drop-{{i}}{{j}}"
                                                   class="toggle">{{itemLvL2.name | translate}}</label>
                                            <a matRipple>
                                                <mat-icon *ngIf="itemLvL2.icon">{{itemLvL2.icon}}</mat-icon>
                                                {{itemLvL2.name | translate}}</a>
                                            <input type="checkbox" id="drop-{{i}}{{j}}"/>
                                            <!-- Level 3 -->
                                            <ul>
                                                <li *ngFor="let itemLvL3 of itemLvL2.sub"
                                                    routerLinkActive="open">
                                                    <a matRipple
                                                       routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}">
                                                        <mat-icon
                                                                *ngIf="itemLvL3.icon">{{itemLvL3.icon}}</mat-icon>
                                                        {{itemLvL3.name | translate}}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
                <button routerLink="/admin" fxFlex="nogrow" mat-stroked-button class="mx-8">Mi cuenta</button>

            </div>

            <div class="d-flex justify-content-end"
                 [ngStyle]="{'border-radius': '10px 0 0 0', 'background-color':'#CFD8DC'}">
                <button class="social-button px-4" mat-icon-button>
                    <fa-icon [icon]="['fab', 'whatsapp']"></fa-icon>
                </button>
                <button class="social-button px-4" mat-icon-button>
                    <fa-icon [icon]="['fab', 'facebook']"></fa-icon>
                </button>
                <button class="social-button px-4" mat-icon-button>
                    <fa-icon [icon]="['fab', 'linkedin']"></fa-icon>
                </button>
                <button class="social-button px-4" mat-icon-button>
                    <fa-icon [icon]="['fas', 'square-rss']"></fa-icon>
                </button>
                <button class="social-button px-4" mat-icon-button>
                    <fa-icon [icon]="['fab', 'twitter']"></fa-icon>
                </button>
            </div>
        </div>

    </div>

</div>
