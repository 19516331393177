import {Component, OnInit, Input, ViewChild, ElementRef} from '@angular/core';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.template.html'
})
export class SidenavComponent {
    @Input('items') public menuItems: any[] = [];
    @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
    @Input('iconMenuTitle') public iconTypeMenuTitle: string;
    @ViewChild('sidenav') sidenav: ElementRef;

    constructor() {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            const links = this.sidenav.nativeElement.querySelectorAll('li[appdropdownlink]');
            [...links].forEach(link => {
                if (link.querySelector('a.open')) {
                    link.classList.add('open');
                }
            })
        })
    }

    // Only for demo purpose
    addMenuItem() {
        this.menuItems.push({
            name: 'ITEM',
            type: 'dropDown',
            tooltip: 'Item',
            icon: 'done',
            state: 'material',
            sub: [
                {name: 'SUBITEM', state: 'cards'},
                {name: 'SUBITEM', state: 'buttons'}
            ]
        });
    }

    toggleEnabled(itemClicked) {
        // Encuentra todos los elementos del menú que están abiertos
        const openItems = this.sidenav.nativeElement.querySelectorAll('li.open');

        if (!itemClicked.classList.contains('open')) {
            itemClicked.classList.add('open');
        }
        // Cierra todos los elementos del menú que están abiertos
        openItems.forEach(item => {
            if (item !== itemClicked && item.classList.contains('open')) {
                item.classList.remove('open');
            }
        });

        // Si el elemento en el que se hizo clic no está abierto, ábrelo
    }
}