import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, ActivatedRouteSnapshot, Params, PRIMARY_OUTLET } from "@angular/router";

interface IRoutePart {
  title: string,
  breadcrumb: string,
  params?: Params,
  url: string,
  urlSegments: any[]
}

@Injectable()
export class RoutePartsService {
  public routeParts: IRoutePart[];
  constructor(private router: Router) {}

  ngOnInit() {
  }

  capitalizeFirstLetter(text: string) {
    let newText = text.split(" ")
    let formatted = "";
    let position = 0
    for (let t of newText) {
      formatted += t.charAt(0).toUpperCase() + t.slice(1)
      position++;
      if (position < newText.length) {
        formatted += ' '

      }
    }
    return formatted
  }
  generateRouteParts(snapshot: ActivatedRouteSnapshot): IRoutePart[] {
    var routeParts = <IRoutePart[]>[];
    if (snapshot) {
      if (snapshot.firstChild) {
        routeParts = routeParts.concat(this.generateRouteParts(snapshot.firstChild));
      }
      if (snapshot.data['title'] && snapshot.url.length) {
        let title =snapshot.data['title']
        let breadcrumb =snapshot.data['breadcrumb']
        routeParts.push({
          title: title,
          breadcrumb: breadcrumb,
          url: snapshot.url[0].path,
          urlSegments: snapshot.url,
          params: snapshot.params
        });
        if (snapshot.data['title'].includes('ofreciendo o buscando propiedades en ')) {
          let city = snapshot.url[1].path.replace('-', ' ')
          city = this.capitalizeFirstLetter(city.replace(/-/g, ''))
          title =snapshot.data['title'] + ` ${city}`
          breadcrumb = city
          routeParts.push({
            title: title,
            breadcrumb: breadcrumb,
            url: `${snapshot.url[0].path}/${snapshot.url[1].path}`,
            urlSegments: snapshot.url,
            params: snapshot.params
          });
        }
      }
    }
    return routeParts;
  }
}
