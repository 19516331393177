import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedMaterialModule} from '../shared-material.module';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PerfectScrollbarModule} from 'app/shared/components/perfect-scrollbar';
import {SearchModule} from '../search/search.module';
import {SharedPipesModule} from '../pipes/shared-pipes.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SharedDirectivesModule} from '../directives/shared-directives.module';

// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import {HeaderSideComponent} from './header-side/header-side.component';
import {SidebarSideComponent} from './sidebar-side/sidebar-side.component';

// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import {HeaderTopComponent} from './header-top/header-top.component';
import {SidebarTopComponent} from './sidebar-top/sidebar-top.component';

// ONLY FOR DEMO
import {CustomizerComponent} from './customizer/customizer.component';

// ALWAYS REQUIRED 
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {SidenavComponent} from './sidenav/sidenav.component';
import {FooterComponent} from './footer/footer.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {AppComfirmComponent} from '../services/app-confirm/app-confirm.component';
import {AppLoaderComponent} from '../services/app-loader/app-loader.component';
import {ButtonLoadingComponent} from './button-loading/button-loading.component';
import {EgretSidebarComponent} from './egret-sidebar/egret-sidebar.component';
import {AppReportComponent} from "../services/app-report/app-report.component";
import {AppNotificationComponent} from "../services/app-notification/app-notification.component";
import {PublicLayoutComponent} from "./layouts/public-layout/public-layout.component";
import {HeaderPublicComponent} from "./header-public/header-public.component";
import {BreadcrumbCenterComponent} from "./breadcrumb-center/breadcrumb-center.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {SearchPanelComponent} from "./search-panel/search-panel.component";
import {AppAddComponent} from "../services/app-add/app-add.component";
import {AppEditService} from "../services/app-edit/app-edit.service";
import {AppEditRowComponent} from "../services/app-edit-row/app-edit-row.component";
import {AppEditComponent} from "../services/app-edit/app-edit.component";


const components = [
    HeaderTopComponent,
    HeaderPublicComponent,
    SidebarTopComponent,
    SidenavComponent,
    NotificationsComponent,
    SidebarSideComponent,
    HeaderSideComponent,
    AdminLayoutComponent,
    PublicLayoutComponent,
    AuthLayoutComponent,
    BreadcrumbComponent,
    BreadcrumbCenterComponent,
    AppComfirmComponent,
    AppReportComponent,
    AppNotificationComponent,
    AppLoaderComponent,
    CustomizerComponent,
    ButtonLoadingComponent,
    EgretSidebarComponent,
    FooterComponent,
    SearchPanelComponent,
    AppEditComponent,
    AppEditRowComponent,
    AppAddComponent
]

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        FlexLayoutModule,
        PerfectScrollbarModule,
        SearchModule,
        SharedPipesModule,
        SharedDirectivesModule,
        SharedMaterialModule,
        FontAwesomeModule,
        ReactiveFormsModule
    ],
    declarations: components,
    // entryComponents: [AppComfirmComponent, AppLoaderComponent, BottomSheetShareComponent],
    exports: components
})
export class SharedComponentsModule {
}
