// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.

import {config} from "config";

export const environment = {
    production: false,
    gps_session: 'pullman_las_condes',
    gps_login: 'monitoreogps_lascondes@wit.la',
    gps_pass: 'MonitoreoLasCondes',
    socket: 'https://cgi.wit.la',
    apiURL: config.apiUrl
};
