import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import {PublicLayoutComponent} from "./shared/components/layouts/public-layout/public-layout.component";
import {ValidateComponent} from "./views/public/validate/validate.component";

export const rootRouterConfig: Routes = [
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./views/admin/admin.module').then(m => m.AdminModule),
        data: { title: 'Pullman CGI', breadcrumb: 'Pullman CGI'}
      },
    ]
  },
  {
    path: 'validate/:seed/:code',
    component: ValidateComponent,
    data: {title: 'Validación', breadcrumb: 'Validación'}
  },
  {
    path: 'broker',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./views/broker/broker.module').then(m => m.BrokerModule),
        data: { title: 'Corredores', breadcrumb: 'Corredores'}
      },
    ]
  },
  {
    path: 'sessions',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Inicio'}
      }
    ]
  },
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./views/public/public.module').then(m => m.PublicModule),
        data: { title: 'WIT', breadcrumb: 'WIT'}
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

