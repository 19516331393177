import {Injectable} from '@angular/core'
import {HttpClient, HttpHeaders} from '@angular/common/http'
import { environment } from "environments/environment";
import {Observable} from "rxjs";
import {LocalStoreService} from "../local-store.service";

@Injectable({
    providedIn: 'root'
})
export class ApiCostaService {
    apiURL= 'https://s1.ntic.cl/totem-costa-handler/'
    apiURLTicketSimply= 'https://s1.ntic.cl/kupos-parser/'
    apiURLTicketSimplyPullman= 'https://s1.ntic.cl/kupos-parser/'

    constructor (private http: HttpClient,
                 private ls: LocalStoreService) {
    }

    ticketInfo(code):Observable<any> {
        return this.http.get<any[]>(`${this.apiURL}ticket_copy.php?code=${code}`, {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                })
        });
    }
    ticketInfoCosta(code):Observable<any> {
        return this.http.get<any[]>(`https://s1.ntic.cl/kupos-parser-costa/?get_ticket=&ticket=${code}`, {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                })
        });
    }
    ticketInfoPullman(code):Observable<any> {
        return this.http.get<any[]>(`https://s1.ntic.cl/kupos-parser/?get_ticket=&ticket=${code}`, {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                })
        });
    }
    servicesInfo(origin, destiny, date) {
        return this.http.get<any[]>(`${this.apiURLTicketSimply}?routes_availability=&origin=${origin}&destiny=${destiny}&date=${date}`, {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                })
        });
    }
    servicesInfoPullman(origin, destiny, date) {
        return this.http.get<any[]>(`${this.apiURLTicketSimplyPullman}?routes_availability=&origin=${origin}&destiny=${destiny}&date=${date}`, {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                })
        });
    }
    loginMasGPS():Observable<any> {
        return this.http.get<any[]>(`${this.apiURLTicketSimply}?hash_mas_gps&u=${environment.gps_login}&p=${environment.gps_pass}`, {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                })
        });
    }

    tracker_list(hash_client): Observable<any> {
        const hash = this.ls.getItem(hash_client)
        return this.http.get<any[]>(`${this.apiURLTicketSimply}?get_trackers&hash=${hash}`, {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                })
        })
    }

    get_counters_token(): Observable<any> {
        return this.http.get<any[]>(`${this.apiURLTicketSimply}?get_counters_token`, {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                })
        })
    }

    counter_api_get(endpoint, token): Observable<any> {
        return this.http.get<any[]>(`https://masgps-counters.witservices.io/iklab/${endpoint}?atoken=${token}`, {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                })
        })
    }

    counter_api_post(endpoint, token, payload): Observable<any> {
        return this.http.post<any[]>(`https://masgps-counters.witservices.io/iklab/${endpoint}?atoken=${token}`, payload, {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                })
        })
    }

}
