import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-confirm',
    template: `<h1 matDialogTitle class="mb-8">{{ data.title }}</h1>
    <div mat-dialog-content [perfectScrollbar] class="mb-16 overflow-hidden">
        <form [formGroup]="editForm"
              fxLayout="column" fxLayoutGap="8px">
            <div *ngFor="let field of data.editFields"
                 [fxFlex]="''"
                 fxFlex.lt-sm="">
                <ng-container [ngSwitch]="field.type">
                    <ng-container *ngSwitchCase="'input'">
                        <mat-form-field class="w-100">
                            <mat-label>{{ field.label }}</mat-label>
                            <input matInput [type]="field.inputType" [formControlName]="field.name"
                                   [placeholder]="field.placeholder" [required]="field.required">
                            <mat-icon matSuffix *ngIf="field.inputType === 'text'">text_fields</mat-icon>
                            <mat-icon matSuffix *ngIf="field.inputType === 'number'">calculate</mat-icon>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngSwitchCase="'select'">
                        <mat-form-field class="w-100">
                            <mat-label>{{ field.label }}</mat-label>
                            <mat-select [formControlName]="field.name">
                                <mat-option *ngFor="let option of field.options"
                                            [value]="option.value">{{ option.option }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngSwitchCase="'textarea'">
                        <mat-form-field class="w-100">
                            <mat-label>{{ field.label }}</mat-label>
                            <textarea matInput [formControlName]="field.name"
                                      [placeholder]="field.placeholder"></textarea>
                        </mat-form-field>
                    </ng-container>
                </ng-container>
            </div>
        </form>
    </div>
    <div mat-dialog-actions class="pb-16">
        <button
                type="button"
                mat-raised-button
                color="primary"
                [disabled]="!editForm.valid"
                (click)="dialogRef.close(editForm.value)">OK
        </button>
        &nbsp;
        <span fxFlex></span>
        <button
                type="button"
                color="accent"
                class="text-center"
                mat-raised-button
                (click)="dialogRef.close(null)">Cancelar
        </button>
    </div>`,
})
export class AppAddComponent {
    editForm: FormGroup

    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<AppAddComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.createForm(data.editFields)
        for (const key in data.item) {
            if (data.item.hasOwnProperty(key)) {
                this.editForm.get(key).setValue(data.item[key])
            }
        }

    }

    createForm(formFields: any) {
        const formGroup: any = {};
        formFields.forEach(field => {
            if (field.type === 'button') return; // Los botones no son parte del FormGroup
            let validators = [];
            if (field.required) {
                validators.push(Validators.required);
            }
            if (field.type === 'input' && field.inputType === 'number') {
                validators.push(Validators.pattern(/^\d+$/));
            }
            formGroup[field.name] = ['', validators];
        });
        this.editForm = this.fb.group(formGroup);

    }
}
