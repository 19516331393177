<div class="sidebar-panel">
    <div id="scroll-area" class="navigation-hold" fxLayout="column">
        <div class="sidebar-hold" [perfectScrollbar] >

            <!-- App Logo -->
            <div class="branding">
                <img src="assets/images/logo-wit.png" alt="" class="app-logo">

                <span style="margin: auto" *ngIf="layoutConf?.sidebarStyle !== 'compact'"></span>
                <div
                class="sidebar-compact-switch"
                [ngClass]="{active: layoutConf?.sidebarCompactToggle}"
                (click)="toggleCollapse()"
                *ngIf="layoutConf?.sidebarStyle !== 'compact'"><span></span></div>
            </div>
            <!-- Sidebar user -->

            <!-- Navigation -->
            <app-sidenav [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle"></app-sidenav>
        </div>
    </div>
</div>
