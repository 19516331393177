import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";


@Component({
    selector: 'app-confirm',
    template: `<h1 matDialogTitle class="mb-8">{{ data.title }}</h1>
    <div mat-dialog-content [perfectScrollbar] class="mb-16 overflow-hidden">
        <div fxLayout="column">
            <div fxFlex=""  fxLayout="row">
                <div [fxFlex]="''"></div>
                <div *ngFor="let column of  data.editFields.cols.options; index as col_index"
                     [fxFlex]="''">
                    <span>{{column.option}}</span>
                </div>
            </div>
            <div fxFlex="" *ngFor="let row of data.editFields.rows.options; index as row_index" fxLayout="row">
                <div [fxFlex]="''">
                    <span>{{row.option}}</span>
                </div>
                <div *ngFor="let column of  data.editFields.cols.options; index as col_index"
                     [fxFlex]="''">
                    <mat-checkbox [(ngModel)]="data.editFields.matrix[row_index][col_index]"></mat-checkbox>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="pb-16">
        <span fxFlex>&nbsp;</span>
        <button
                type="button"
                color="accent"
                class="text-center"
                mat-raised-button
                (click)="dialogRef.close(null)">Cancelar
        </button>
        <button
                type="button"
                mat-raised-button
                color="primary"
                (click)="dialogRef.close(data.editFields.matrix)">Confirmar
        </button>
    </div>`,
})
export class AppEditRowComponent {
    editForm: FormGroup

    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<AppEditRowComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {


    }

}
