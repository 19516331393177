import {Pipe, PipeTransform} from "@angular/core";
import {utcToZonedTime} from 'date-fns-tz';

@Pipe({name: 'relativeTime'})
export class RelativeTimePipe implements PipeTransform {
    transform(value: Date) {
        if (typeof value === 'string')
            value = new Date(value);

        let santiagoDate = utcToZonedTime(value, 'America/Santiago');
        console.log(santiagoDate)
        let seconds: number = Math.floor(((new Date()).getTime() - santiagoDate.getTime()) / 1000);
        let interval: number = Math.floor(seconds / 31536000);
        if (interval > 1) {
            return "Hace " + interval + " año(s)";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
            return "Hace " + interval + " meses";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
            return "Hace " + interval + " días";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
            return "Hace " + interval + " horas";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
            return "Hace " + interval + " minutos";
        }
        return "Hace " + Math.floor(seconds) + " segundos";
    }
}
