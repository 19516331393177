import { Injectable } from "@angular/core";
import { LocalStoreService } from "../local-store.service";
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { map, catchError, delay } from "rxjs/operators";
import { User } from "../../models/user.model";
import {of, BehaviorSubject, throwError, Observable} from "rxjs";
import { environment } from "environments/environment";
import {ApiCostaService} from "../api-costa/api-costa.service";

// ================= only for demo purpose ===========
const DEMO_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1YjhkNDc4MDc4NmM3MjE3MjBkYzU1NzMiLCJlbWFpbCI6InJhZmkuYm9ncmFAZ21haWwuY29tIiwicm9sZSI6IlNBIiwiYWN0aXZlIjp0cnVlLCJpYXQiOjE1ODc3MTc2NTgsImV4cCI6MTU4ODMyMjQ1OH0.dXw0ySun5ex98dOzTEk0lkmXJvxg3Qgz4ed";

const DEMO_USER: User = {
  id: "5b700c45639d2c0c54b354ba",
  displayName: "Watson Joyce",
  role: "SA",
};
// ================= you will get those data from server =======

@Injectable({
  providedIn: "root",
})
export class JwtAuthService {
  token;
  isAuthenticated: Boolean;
  user: User = {};
  user$ = (new BehaviorSubject<User>(this.user));
  signingIn: Boolean;
  return: string;
  JWT_TOKEN = "WIT_JWT_TOKEN";
  APP_USER = "WIT_USER";

  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private api: ApiCostaService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams
      .subscribe(params => this.return = params['return'] || '/admin');
  }

  public signin(username, password):Observable<any> {
    this.signingIn = true;
    this.signingIn = true;

    const body = {
      username: username,
      password: password
    }
    return this.http.post(`${environment.socket}/login`, body)
        .pipe(
            map((res: any) => {
              if (res.success) {
                this.setUserAndToken(res.token, res.user, true);
                this.signingIn = false;
                return res;
              } else {
                this.signingIn = false;
                return res;
              }
            }),
            catchError((error) => {
              return throwError(error);
            })
        );

  }
  public checkTokenIsValid() {
    return this.http.get(`${environment.socket}/user`, {headers: {'Authorization': this.getJwtToken() }})
        .pipe(
            map((res: any) => {
              if (res.success) {
                this.setUserAndToken(res.token, res.user, true);
                this.api.loginMasGPS().subscribe(data => {
                  this.ls.setItem(environment.gps_session, data.hash)
                })
                this.signingIn = false;
                return res;
              } else {
                this.signingIn = false;
                return res;
              }
              return res.user;
            }),
            catchError((error) => {
              return of(error);
            })
        );
  }

  public signout() {
    this.setUserAndToken(null, null, false);
    this.router.navigateByUrl("sessions/signin");
  }

  isLoggedIn(): Boolean {
    return !!this.getJwtToken();
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }
  getUser() {
    return this.ls.getItem(this.APP_USER);
  }

  setUserAndToken(token: String, user: User, isAuthenticated: Boolean) {
    this.isAuthenticated = isAuthenticated;
    this.token = token;
    this.user = user;
    this.user$.next(user);
    this.ls.setItem(this.JWT_TOKEN, token);
    this.ls.setItem(this.APP_USER, user);
  }
}
