export const category_list: any[] = [
    {
        "category": "Mi cuenta",
        "slug": "/sessions/signin",
        "description": "Ingresar al sistema",
        "order": "1",
        "section": "WIT",
        "section_slug": "wit"
    }
]
