export const search_panel_constants = {
    filters: [
        {
            "title": "Ubicación",
            "name": "location",
            "description": "Selecciona la ubicación en la que estás interesado. Puedes elegir una ciudad, un barrio o incluso una calle específica.",
            "type": "select",
            "required": true,
            "isCustom": false
        },
        {
            "title": "Tipo de operación",
            "name": "operation",
            "description": "Selecciona el tipo de operación que deseas realizar. Puedes elegir entre comprar o alquilar.",
            "type": "select",
            "required": true,
            "isCustom": false
        },
        {
            "title": "Tipo de propiedad",
            "name": "type",
            "description": "Selecciona el tipo de propiedad que estás buscando. Puedes elegir entre casa, apartamento, estudio, etc.",
            "type": "select",
            "required": false,
            "isCustom": false
        },
        {
            "title": "Precio",
            "name": "price",
            "description": "Introduce el rango de precios que estás dispuesto a pagar. Puedes especificar un precio mínimo y máximo.",
            "type": "input",
            "required": false,
            "isCustom": true
        },
        {
            "title": "Superficie",
            "name": "size",
            "description": "Introduce la superficie mínima y máxima (en metros cuadrados) que deseas para la propiedad.",
            "type": "input",
            "required": false,
            "isCustom": true
        },
        {
            "title": "Número de habitaciones",
            "name": "rooms",
            "description": "Indica el número mínimo y máximo de habitaciones que deseas para la propiedad.",
            "type": "input",
            "required": false,
            "isCustom": true
        },
        {
            "title": "Número de baños",
            "name": "bathrooms",
            "description": "Indica el número mínimo y máximo de baños que deseas para la propiedad.",
            "type": "input",
            "required": false,
            "isCustom": true
        },
        {
            "title": "Extras",
            "name": "extras",
            "description": "Selecciona las comodidades o extras que te gustaría que tuviera la propiedad, como piscina, garaje, jardín, etc.",
            "type": "checkbox",
            "required": false,
            "isCustom": true
        }
        // Agrega más propiedades personalizadas según tus necesidades
    ]
}
