<mat-card class="mx-0 my-8" *ngIf="searchInformation">
    <mat-card-content>
        <form [formGroup]="searchInformation"
              fxLayout="row wrap"
              fxLayout.lt-sm="column"
              fxLayoutGap="8px"
              (ngSubmit)="search()">
            <ng-template matStepLabel>Agrega un usuario</ng-template>

            <mat-form-field fxFlex="" class="" *ngFor="let filter of filters_enabled" [matTooltip]="filter.description">
                <input matInput
                       *ngIf="filter.type == 'input'"
                       [placeholder]="filter.title"
                       [formControlName]="filter.name"
                       [required]="filter.required">
                <mat-select
                        *ngIf="filter.type == 'select'"
                        [placeholder]="filter.title"
                        [formControlName]="filter.name"
                        [required]="filter.required">
                    <mat-option *ngFor="let option of select_options[filter.name]"></mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-raised-button
                    *ngIf="searchInformation"
                    [color]="searchInformation.valid ? 'primary' : null"
                    [disabled]="searchInformation.invalid"
                    type="submit">
                Buscar
            </button>
        </form>

        <mat-accordion *ngIf="customFilters">
            <mat-expansion-panel>
                <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px"
                                            class=" light-gray egret-example-viewer-header">
                    <mat-panel-title>
                        Información de quien genera el documento
                    </mat-panel-title>
                </mat-expansion-panel-header>
            </mat-expansion-panel>

        </mat-accordion>
    </mat-card-content>


    <div fxLayout="row" class="justify-content-end px-16 py-8">

    </div>
</mat-card>
