import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {egretAnimations} from "../../../shared/animations/egret-animations";
import * as CryptoJS from 'crypto-js';

@Component({
    selector: 'validate',
    templateUrl: './validate.component.html',
    styleUrls: ['./validate.component.css'],
    animations: egretAnimations
})
export class ValidateComponent implements OnInit, OnDestroy {

    public getItemSub: Subscription;
    private code: string;
    private seed: string;
    public ticketData: any;

    constructor(
        public router: Router,
        private route: ActivatedRoute
    ) {
    }

    decryptData(ciphertext: string, seed: string): any {
        const bytes = CryptoJS.AES.decrypt(ciphertext, seed);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedData);
    }

    encryptData(data: any, seed: string): string {
        const jsonString = JSON.stringify(data);
        return CryptoJS.AES.encrypt(jsonString, seed).toString();
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.code = params.get('code');
            this.seed = params.get('seed');
            console.log("this.code", this.code)
            console.log("this.seed", this.seed)
            try {
                const decoded = this.decryptData(this.code, this.seed)
                if (decoded) {
                    this.ticketData = decoded
                }

            } catch (e) {
                console.log("error de decodificacion")
            }

        });
    }

    ngOnDestroy() {
        if (this.getItemSub) {
            this.getItemSub.unsubscribe();
        }
    }
}