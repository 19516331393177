import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
// SERVICES
import {ThemeService} from './services/theme.service';
import {RoutePartsService} from './services/route-parts.service';
import {AuthGuard} from './guards/auth.guard';
import {UserRoleGuard} from './guards/user-role.guard';
import {AppConfirmService} from './services/app-confirm/app-confirm.service';
import {AppLoaderService} from './services/app-loader/app-loader.service';

import {SharedComponentsModule} from './components/shared-components.module';
import {SharedPipesModule} from './pipes/shared-pipes.module';
import {SharedDirectivesModule} from './directives/shared-directives.module';
import {NavigationPublicService} from "./services/navigation-public.service";
import {NavigationService} from './services/navigation.service';
import {ApiJsonService} from "./services/api-json/api-json.service";
import {SocketIoConfig, SocketIoModule} from "ngx-socket-io";
import {AppAddService} from "./services/app-add/app-add.service";
import {environment} from "../../environments/environment";
import {AppEditService} from "./services/app-edit/app-edit.service";
import {AppEditRowService} from "./services/app-edit-row/app-edit-row.service";

const config: SocketIoConfig = { url: environment.socket, options: {} };

@NgModule({
    imports: [
        CommonModule,
        SharedComponentsModule,
        SharedPipesModule,
        SharedDirectivesModule,
        SocketIoModule.forRoot(config)
    ],
    providers: [
        ThemeService,
        NavigationPublicService,
        NavigationService,
        RoutePartsService,
        AuthGuard,
        UserRoleGuard,
        AppConfirmService,
        AppEditService,
        AppEditRowService,
        AppAddService,
        AppLoaderService,
        ApiJsonService,

    ],
    exports: [
        SharedComponentsModule,
        SharedPipesModule,
        SharedDirectivesModule
    ]
})
export class SharedModule {
}
