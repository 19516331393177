import {Component, OnInit, OnDestroy, AfterViewInit} from "@angular/core";
import {Subscription} from "rxjs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {search_panel_constants} from "./search-panel.constants";

@Component({
    selector: "app-search-panel",
    templateUrl: "./search-panel.component.html"
})
export class SearchPanelComponent implements OnInit, OnDestroy, AfterViewInit {
    private menuItemsSub: Subscription;
    public searchInformation: FormGroup;
    public select_options = [];
    public filters = search_panel_constants.filters;
    public filters_enabled = search_panel_constants.filters;
    public customFilters: boolean = false;

    constructor(
        private fb: FormBuilder,
    ) {
    }

    ngOnInit() {
        let group = {}
        this.filters_enabled = this.filters.filter(f => !f.isCustom)
        this.filters.map(f => {
            this.select_options[f.name] = []
        })
        for (let filter of this.filters_enabled) {
            let control: FormControl;
            if (filter.required) {
                control = new FormControl('', Validators.required);
            } else {
                control = new FormControl('');
            }
            group[filter.name] = control;
        }

        this.searchInformation = this.fb.group(group);
    }

    search() {

    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
        if (this.menuItemsSub) {
            this.menuItemsSub.unsubscribe();
        }
    }
}
