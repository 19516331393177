<div class="container-fluid">
  <div
    class="breadcrumb-bar"
    *ngIf="
      layout.layoutConf.useBreadcrumb &&
      layout.layoutConf.breadcrumb === 'simple'
    "
  >
    <ul class="breadcrumb">
      <li *ngFor="let part of routeParts">
        <a routerLink="/{{ part.url }}">{{ part.breadcrumb | translate }}</a>
      </li>
    </ul>
  </div>

  <div
    class="breadcrumb-title justify-content-center"
    *ngIf="
      layout.layoutConf.useBreadcrumb &&
      layout.layoutConf.breadcrumb === 'title'
    "
  >
    <h1 class="fullwidth text-center text-white">WIT </h1>
    <h2 class="fullwidth text-center text-muted-white">
      Tu mejor aliado para expandir tu cartera de clientes en el mercado inmobiliario.
    </h2>
    <ul class="breadcrumb" *ngIf="routeParts.length > 1">
      <li *ngFor="let part of routeParts">
        <a routerLink="/{{ part.url }}" class="text-muted-white">{{
          part.breadcrumb | translate
        }}</a>
      </li>
    </ul>
  </div>
</div>
