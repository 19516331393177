import {Injectable} from '@angular/core'
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Observable} from 'rxjs'
import {environment} from "environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ApiJsonService {
    socket = null

    constructor(private http: HttpClient) {
    }

    getIndex(): Observable<any[]> {
        return this.http.get<any[]>(`/assets/json/index.json`, {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                })
        });
    }
    getCategory(category): Observable<any[]> {
        return this.http.get<any[]>(`/assets/json/category/${category}.json`, {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                })
        });
    }
    getPost(post): Observable<any[]> {
        return this.http.get<any[]>(`/assets/json/posts/${post}.json`, {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                })
        });
    }



}
